import React from 'react';
import ReactMarkdown from "react-markdown";

const privacyPolicyContent = `
# Privacy Policy

Aster Ave LLC (“Company,” “we,” “us,” or “our”) respects your privacy and is committed to protecting it through our compliance with this policy. This Privacy Policy describes the types of information we may collect from you or that you may provide when you visit the website [www.asteragents.com](http://www.asteragents.com) (the “Site”), as well as our practices for collecting, using, maintaining, protecting, and disclosing that information.

By using the Site, you agree to the collection and use of information in accordance with this Privacy Policy. This policy may change from time to time (see Changes to Our Privacy Policy), so please check it periodically.

## 1. Information We Collect

We collect several types of information from and about users of our Site, including:

### a. Personal Information

When you create an account or use certain features on our Site, we may collect personal information that can identify you directly or indirectly. This may include:

- **Identifiers:** Name, email address, postal address, phone number, account information.
- **Account Information:** Login credentials, profile information.

### b. Non-Personal Information

We may collect non-identifiable information automatically as you navigate through the Site. This can include:

- **Usage Data:** Information about your interaction with our Site, such as pages visited, time spent on each page, and links clicked.
- **Device Information:** Internet Protocol (IP) addresses, browser type, operating system, and other technical data.

### c. Information from AI Models

Our Site may include content or responses generated by artificial intelligence (AI) technologies, including large language models (LLMs). In certain cases, we may collect data related to user interactions with these models, such as questions asked, topics of interest, or feedback provided, to improve our services. No sensitive personal information should be submitted when interacting with AI-driven features.

## 2. How We Use Your Information

We use information that we collect about you or that you provide to us, including personal information, for various purposes:

- **To Provide and Improve the Site:** Deliver services, process transactions, and personalize user experience.
- **To Communicate with You:** Respond to inquiries, send service-related communications, and provide updates.
- **For Analytics and Research:** Understand user preferences, monitor usage patterns, and improve our services and offerings.
- **To Ensure Legal Compliance:** Comply with applicable laws, regulations, and our Terms of Service.
- **For AI Model Usage:** User interactions with AI features are used as prompts for third-party AI models (such as those from OpenAI, Anthropic, and Google) to generate responses. While we may analyze these interactions to improve our services, we do not train AI models ourselves.

## 3. How We Share Your Information

We may disclose your information to third parties in certain circumstances:

- **With Service Providers:** We may share information with third-party service providers who perform services on our behalf, such as hosting, analytics, and customer support.
- **With Legal and Regulatory Authorities:** To comply with legal obligations or in response to lawful requests by public authorities.
- **In Connection with Business Transfers:** In the event of a merger, acquisition, or sale of all or part of our assets, user information may be transferred as part of that transaction.
- **For AI-Related Data Processing:** Anonymized and aggregated data may be shared with third-party AI providers to improve the performance and reliability of AI features on the Site.

## 4. Cookies and Tracking Technologies

We use cookies and similar tracking technologies to collect information automatically, personalize user experience, and analyze site usage. You may manage cookies through your browser settings; however, disabling cookies may affect your ability to access certain features of the Site.

## 5. Your Choices and Rights

Depending on your location, you may have specific rights regarding your personal information under applicable privacy laws. These may include:

- **Access:** The right to request access to the information we hold about you.
- **Correction:** The right to request correction of inaccurate personal data.
- **Deletion:** The right to request deletion of your personal information, subject to certain legal restrictions.
- **Opt-Out of Marketing Communications:** The right to opt out of receiving promotional or marketing communications from us.

To exercise any of these rights, please contact us at patrick@asterave.com. We will respond to your request as required by applicable law.

## 6. Security of Your Information

We implement appropriate technical and organizational measures to secure your personal information from accidental loss and unauthorized access, use, alteration, or disclosure. However, no method of internet transmission or electronic storage is completely secure, so we cannot guarantee its absolute security.

## 7. AI-Generated Content and Data Use

Our Site may utilize AI-generated content, which is intended to assist with information and recommendations. Please note:

- **No Warranty of Accuracy:** AI-generated content may not always be accurate or up-to-date. You are responsible for independently verifying any critical information.
- **Data Retention and Processing:** AI interactions may be logged for quality improvement. We anonymize and aggregate data where possible to protect user privacy.

## 8. International Data Transfers

If you are located outside of the United States, please be aware that your information may be transferred to, stored, and processed in the United States, where our servers and central databases are operated. By using the Site, you consent to the transfer of your information to the United States.

## 9. Children's Privacy

Our Site is not directed at children under the age of 18, and we do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently received personal information from a child under 18, we will delete such information from our records.

## 10. Changes to Our Privacy Policy

We may update this Privacy Policy from time to time. If we make material changes to how we treat your personal information, we will notify you by email or through a prominent notice on the Site. Your continued use of the Site following the posting of changes constitutes your acceptance of such changes.

## 11. Contact Information

If you have any questions about this Privacy Policy or our privacy practices, please contact us at:

**Aster Ave LLC**  
1901 Aurora Dr  
Raleigh, North Carolina 27615  
Email: patrick@asterave.com
`;

const PrivacyPolicy = () => {
  return (
    <div className="prose mx-auto">
      <ReactMarkdown>{privacyPolicyContent}</ReactMarkdown>
    </div>
  );
};

export default PrivacyPolicy;