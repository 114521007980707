import React, { useRef, useState, useEffect, lazy, Suspense } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useChat } from "ai/react";
import { useAuth } from "@clerk/clerk-react";
import { ChatInputForm } from "./ChatInputForm";
import { ClipboardDocumentIcon, DocumentIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { Combobox, Label, Field, ComboboxButton, ComboboxInput, ComboboxOptions, ComboboxOption } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@radix-ui/react-icons";
import rehypeExternalLinks from "rehype-external-links";
import ErrorBoundary from "./ToolError";


import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { IconSpinner } from "../ui/Icons";

import { useAI } from "../contexts/AIContext";
import { useConversation } from "../contexts/ConversationContext";
import { useAuthenticatedFetch } from "../hooks/use-authenticated-fetch";
import { useAuthenticatedSWR } from "../hooks/use-authenticated-swr";
import { ThreadFeedback } from "./Feedback";

const ScrapeUrl = lazy(() => import("./Tools/ScrapeUrl"));
const AskWeb = lazy(() => import("./Tools/AskWeb"));
const CallApi = lazy(() => import("./Tools/CallApi"));
const SearchSnowflake = lazy(() => import("./Tools/SearchSnowflake"));
const SnowflakeSql = lazy(() => import("./Tools/SnowflakeSql"));
const PostgresSql = lazy(() => import("./Tools/PostgresSql"));
const HybridSearchPG = lazy(() => import("./Tools/HybridSearchPG"));
const KeywordSearchPG = lazy(() => import("./Tools/KeywordSearchPG"));
const SemanticSearchPG = lazy(() => import("./Tools/SemanticSearchPG"));
const SendEmail = lazy(() => import("./Tools/SendEmail"));
const CallAgent = lazy(() => import("./Tools/CallAgent"));
const SearchGoogle = lazy(() => import("./Tools/SearchGoogle"));
const ExecutePython = lazy(() => import("./Tools/ExecutePython"));

export const toolComponentMap = {
  scrape_url: ScrapeUrl,
  ask_web: AskWeb,
  call_api: CallApi,
  search_snowflake: SearchSnowflake,
  snowflake_sql: SnowflakeSql,
  postgres_sql: PostgresSql,
  hybrid_search_pg: HybridSearchPG,
  keyword_search_pg: KeywordSearchPG,
  semantic_search_pg: SemanticSearchPG,
  send_email: SendEmail,
  call_agent: CallAgent,
  search_google: SearchGoogle,
  execute_python: ExecutePython,
};

const Chat = () => {
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const { threadId: paramThreadId } = useParams();
  const authenticatedFetch = useAuthenticatedFetch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, agents, isLoadingAgents } = useAI();
  const { refreshConversations } = useConversation();
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [threadId, setThreadId] = useState(paramThreadId);
  const [files, setFiles] = useState([]);

  // Custom fetch function to include the token in the headers and return the response without parsing it (works with streaming)
  const customFetch = async (url, options) => {
    const token = await getToken();
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  // Function to handle the response and extract the thread ID
  const handleResponse = (response) => {
    const responseThreadId = response.headers.get("X-Thread-ID");
    if (responseThreadId && !threadId) {
      // This is a new conversation, always set the ID and navigate
      setThreadId(responseThreadId);
      navigate(`/chat/${responseThreadId}`);
      refreshConversations();
    }
  };

  const { messages, setMessages, input, setInput, handleSubmit, handleInputChange, isLoading, addToolResult, append, stop } = useChat({
    body: {
      agentId: selectedAgent?.id,
      threadId: threadId || null,
    },
    //headers: { "X-Stream-Response": false }, // test streaming off
    onResponse: handleResponse,
    maxSteps: 20,
    fetch: customFetch,
  });

  const handleFormSubmit = (e) => {
    handleSubmit(e, { experimental_attachments: files });
    setFiles([]); // Clear files after submission
  };

  // SWR for conversations
  const {
    data: conversationData,
    error: conversationError,
    isValidating: isLoadingConversation,
  } = useAuthenticatedSWR(
    // only fetch if paramThreadId is defined and either different from threadId or messages are empty
    paramThreadId && (paramThreadId !== threadId || messages.length === 0) ? `/api/getConversation?threadId=${paramThreadId}` : null
  );

  useEffect(() => {
    if (conversationData && conversationData.messages) {
      const formattedMessages = conversationData.messages
        .filter((msg) => msg.role !== "system")
        .map((msg) => ({
          id: msg.id,
          role: msg.role,
          content: msg.content,
          experimental_attachments: msg.attachments || [],
          createdAt: new Date(msg.createdAt),
          toolInvocations: msg.toolInvocations || undefined,
        }));

      setMessages(formattedMessages);

      // Set the selected agent based on the agentId from the conversation data
      if (conversationData.agentId) {
        const agent = agents.find((a) => a.id === conversationData.agentId);
        if (agent) {
          setSelectedAgent(agent);
        }
      }

      // Update threadId if it's different
      if (paramThreadId !== threadId) {
        setThreadId(paramThreadId);
      }
    }
  }, [conversationData, agents, paramThreadId, threadId]);

  // reset state when navigating to /chat
  useEffect(() => {
    if (location.pathname === "/chat" && !paramThreadId) {
      stop(); // stop any in-flight chat api requests
      setMessages([]);
      setThreadId(null);
      setInput("");

      // Set default agent
      const defaultAgent = agents.find((agent) => agent.id === userInfo?.organizations?.[0]?.settings?.defaultAgentId) || agents[0];
      setSelectedAgent(defaultAgent);
    }
  }, [location.pathname, paramThreadId, agents, userInfo]);

  const handleSuggestionClick = (suggestion) => {
    append({
      role: "user",
      content: suggestion,
    });
  };

  const messagesRef = useRef(null);

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    // You might want to add a toast notification here
  };

  if (isLoadingAgents || isLoadingConversation) {
    return (
      <div className="flex-grow flex justify-center items-center h-full">
        <IconSpinner className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto h-full w-full flex flex-col">
      {messages.length === 0 && (
        <div className="flex flex-col w-full justify-center items-center mb-4">
          <Field className="w-full px-4 lg:px-0 lg:w-1/2">
            <Label>Select an Agent:</Label>
            <Combobox value={selectedAgent} onChange={setSelectedAgent}>
              <div className="relative mt-1">
                <div className="relative w-full cursor-default rounded-lg bg-background border border-input py-3 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-ring focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-ring">
                  <ComboboxInput className="w-full border-none py-2 pl-3 pr-10 text-lg font-medium leading-5 text-gray-900 focus:ring-0" displayValue={(agent) => agent?.name || "Select an agent"} />
                  <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon className="h-5 w-5 text-muted-foreground" aria-hidden="true" />
                  </ComboboxButton>
                </div>
                <ComboboxOptions className="absolute mt-1 w-[var(--input-width)] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                  {agents.map((agent) => (
                    <ComboboxOption key={agent.id} value={agent} className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-accent text-accent-foreground" : "text-gray-900"}`}>
                      {({ selected, active }) => (
                        <>
                          <div className="flex flex-col">
                            <span className={`block truncate text-lg ${selected ? "font-medium" : "font-normal"}`}>{agent.name}</span>
                            <span className="block truncate text-sm text-gray-500">{agent.description}</span>
                          </div>
                          {selected ? (
                            <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-accent-foreground" : "text-primary"}`}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              </div>
            </Combobox>
          </Field>
          <img src={selectedAgent?.logoUrl || "/favicon.ico"} className="mt-20 w-32 h-32 object-cover rounded-lg" alt={`Logo of ${selectedAgent?.name || "Default Agent"}`} />
          <div className="mt-4 flex flex-wrap justify-center">
            {(selectedAgent?.conversationStarters || ["How can I help you?", "Tell me more about your project", "What's on your mind?"]).map((suggestion, index) => (
              <button key={index} className="bg-gray-100 dark:bg-zinc-950 text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-zinc-900 text-sm py-2 px-4 rounded-xl m-1" onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="flex-grow">
        {messages.length > 0 && (
          <div className="px-4">
            <div className="flex items-center justify-center mb-6">
              <img src={selectedAgent?.logoUrl || "/favicon.ico"} className="w-10 h-10 object-cover rounded-lg mr-2" alt={`Logo of ${selectedAgent?.name || "Default Agent"}`} />
              <h2 className="text-xl text-center font-bold">{selectedAgent?.name || "Default Agent"}</h2>
            </div>

            <div className="space-y-4 mb-4" ref={messagesRef}>
              {messages.map((m, index) => (
                <div key={index} className={`flex w-full ${m.role === "assistant" ? "justify-start" : "justify-end"}`}>
                  {m.role === "assistant" && <img src={selectedAgent?.logoUrl || "/favicon.ico"} alt={selectedAgent?.name || "Default Agent"} className="w-8 h-8 object-cover rounded-lg self-start mt-4 mr-2" />}
                  <div className={`${m.role === "assistant" ? "prose prose-stone" : ""}`}>
                    {m.content && (
                      <div className={m.role === "user" ? "bg-gray-100 rounded-xl p-2" : ""}>
                        <ReactMarkdown rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["noopener", "noreferrer"] }]]} remarkPlugins={[remarkGfm]}>
                          {m.content}
                        </ReactMarkdown>
                      </div>
                    )}
                    {m.toolInvocations && m.toolInvocations.length > 0 && (
                      <div>
                        {m.toolInvocations.map((toolInvocation, toolIndex) => {
                          const ToolComponent = toolComponentMap[toolInvocation.toolName];
                          return (
                            <div key={toolIndex} className="mb-4">
                              {ToolComponent ? (
                                <ErrorBoundary fallback={<div>Error loading tool result.</div>}>
                                  <Suspense fallback={<div>Loading tool...</div>}>
                                    <ToolComponent invocation={toolInvocation} />
                                  </Suspense>
                                </ErrorBoundary>
                              ) : (
                                <span className="italic font-light">{`Calling tool: ${toolInvocation.toolName}`}</span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {m.role === "user" && m.experimental_attachments && m.experimental_attachments.length > 0 && (
                      <div className="mt-2 flex flex-wrap justify-end gap-4">
                        {m.experimental_attachments.map((attachment, attachmentIndex) => {
                          const isImage = attachment.contentType && attachment.contentType.startsWith("image/");
                          return (
                            <div key={attachmentIndex} className="flex flex-col items-end">
                              {isImage ? (
                                <>
                                  <img src={attachment.url} alt={attachment.name || `Attachment ${attachmentIndex + 1}`} className="max-h-64 max-w-64 object-cover rounded-md" />
                                  <span className="text-sm mt-1 text-center truncate max-w-[256px]">{attachment.name}</span>
                                </>
                              ) : (
                                <div className="flex items-center p-2 bg-gray-100 rounded-md">
                                  <DocumentIcon className="w-8 h-8 text-gray-500 mr-2" />
                                  <span className="text-sm truncate max-w-[256px]">{attachment.name}</span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {!m.content && !m.toolInvocations && <span className="italic font-light">Processing...</span>}
                    {m.role === "assistant" && m.content && (
                      <div className="group relative inline-block">
                        <ClipboardDocumentIcon className="w-5 h-5 cursor-pointer" onClick={() => copyToClipboard(m.content)} />
                        <span className="invisible group-hover:visible hidden sm:inline-block opacity-0 group-hover:opacity-100 transition bg-gray-900 text-white text-xs p-1 rounded absolute top-full mt-2 whitespace-nowrap">Copy</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {isLoading && (
                <div className="mt-2 p-2 rounded mb-3 text-black text-left max-w-3xl mr-auto mr-0">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              {messages.length >= 2 && !isLoading && (
                <ThreadFeedback 
                  threadId={threadId} 
                  agentId={selectedAgent?.id}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="mt-auto border-t bg-background sm:px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4">
        <ChatInputForm selectedAgent={selectedAgent} input={input} handleFormSubmit={handleFormSubmit} handleInputChange={handleInputChange} isLoading={isLoading} files={files} setFiles={setFiles} />
      </div>
    </div>
  );
};

export default Chat;
