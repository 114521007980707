import React, { useState, useEffect, useMemo } from "react";
import { useAuthenticatedSWR } from "../hooks/use-authenticated-swr";
import { useConversation } from "../contexts/ConversationContext";
import { useAI } from "../contexts/AIContext";
import { formatDistanceToNow, subDays, startOfDay, endOfDay } from "date-fns";
import { Link } from "react-router-dom";
import { PersonIcon } from "@radix-ui/react-icons";

// Dashboard Components
import { AgentCombobox } from "./Dashboard/AgentCombobox";
import { DatePickerWithRange } from "./Dashboard/DateRangePicker";
import DisplayConversation from "./Dashboard/DisplayConversation";

// UI Elements
import { Switch } from "../ui/Switch";
import { Label } from "../ui/Label";
import { Separator } from "../ui/Separator";
import { IconSpinner } from "../ui/Icons";
import { Badge } from "../ui/Badge";
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from "../ui/Tooltip";

const Drawer = () => {
  return (
    <div className="overflow-hidden bg-white border border-gray-200 rounded-xl">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-bold text-gray-900">Agent Performance</h3>
        <div className="mt-4" style={{ height: "300px" }}>
          {/* Placeholder for a chart or graph */}
          <div className="flex items-center justify-center h-full bg-gray-100 rounded">
            <p className="text-gray-500">Agent Performance Chart</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [dateRange, setDateRange] = React.useState({
    from: startOfDay(subDays(new Date(), 14)),
    to: endOfDay(new Date()),
  });
  const safeFromDate = dateRange.from || startOfDay(subDays(new Date(), 14));
  const safeToDate = dateRange.to || endOfDay(new Date());

  const [selectedAgent, setSelectedAgent] = useState("");

  const { userInfo, agents, isLoadingAgents, isAdmin } = useAI();
  const { data: stats, error, isLoading: isLoadingStats } = useAuthenticatedSWR(`/api/dashboard/getStats?from=${safeFromDate.toISOString()}&to=${safeToDate.toISOString()}&agentId=${selectedAgent}`);

  const { data: allStats, isLoading: isLoadingAllStats } = useAuthenticatedSWR(isAdmin ? `/api/dashboard/getStats?allInOrg=true&from=${safeFromDate.toISOString()}&to=${safeToDate.toISOString()}&agentId=${selectedAgent}` : null);

  const { data: conversations, isLoading: isLoadingConversations } = useAuthenticatedSWR(`/api/dashboard/getInteractions?from=${safeFromDate.toISOString()}&to=${safeToDate.toISOString()}`,
{
  swrOptions: {
    fallbackData: [],
  },
});

  const { data: allConversations, isLoading: isLoadingAllConversations } = useAuthenticatedSWR(isAdmin ? `/api/dashboard/getInteractions?allInOrg=true&from=${safeFromDate.toISOString()}&to=${safeToDate.toISOString()}` : null,
{
  swrOptions: {
    fallbackData: [],
  },
});

  const [isAdminView, setIsAdminView] = useState(isAdmin);
  const conversationsToShow = isAdminView ? allConversations : conversations;
  const isLoadingConversationsToShow = isAdminView ? isLoadingAllConversations : isLoadingConversations;
  const statsToShow = isAdminView ? allStats : stats;
  const isLoadingStatsToShow = isAdminView ? isLoadingAllStats : isLoadingStats;
  const filteredConversations = conversationsToShow && selectedAgent
    ? conversationsToShow.filter((conv) => conv.agentId === selectedAgent)
    : conversationsToShow || [];

  // little use effect to set the admin view to the current user's admin status, once we have it
  useEffect(() => {
    if (isAdmin !== undefined) {
      setIsAdminView(isAdmin);
    }
  }, [isAdmin]);

  // Calculate feedback score
  const feedbackScore = statsToShow?.totalFeedback > 0
    ? Math.round((statsToShow.totalHelpful / statsToShow.totalFeedback) * 100)
    : null;

  return (
    <div className="flex flex-col flex-1 overflow-x-hidden relative">
      <main>
        <div className="py-6">
          <div className="px-4 mx-auto sm:px-6 md:px-8">
            <div className="md:items-center md:flex justify-between">
              <div className="flex flex-col md:flex-row md:items-center">
                <p className="text-base font-bold text-gray-900">AI Agent Dashboard</p>
                <p className="mt-1 text-base font-medium text-gray-500 md:mt-0 md:ml-2">Monitor and observe your AI agents' performance</p>
              </div>
            </div>
          </div>

          <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
            <div className="space-y-5 sm:space-y-6">
              <div className="bg-gray-50 border border-gray-200 rounded-xl p-5 shadow-sm">
                <div className="flex h-auto lg:h-8 flex-wrap items-center gap-4">
                  {isAdmin && (
                    <div className="flex items-center space-x-2">
                      <Switch id="admin-view" checked={isAdminView} onCheckedChange={setIsAdminView} />
                      <Label htmlFor="admin-view">Admin View</Label>
                    </div>
                  )}
                  {isAdmin && <Separator orientation="vertical" />}
                  <div className="flex items-center">
                    <AgentCombobox selectedAgent={selectedAgent} setSelectedAgent={setSelectedAgent} />
                  </div>
                  <Separator orientation="vertical" />
                  <DatePickerWithRange dateRange={dateRange} setDateRange={setDateRange} />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <div className="bg-white border border-gray-200 rounded-xl">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Conversations</p>
                    <div className="flex items-center justify-between mt-3">
                      {isLoadingStatsToShow ? <IconSpinner className="text-gray-500" /> : <p className="text-xl font-bold text-gray-900">{statsToShow?.conversationCount || 0}</p>}
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-xl">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Messages</p>
                    <div className="flex items-center justify-between mt-3">
                      {isLoadingStatsToShow ? <IconSpinner className="text-gray-500" /> : <p className="text-xl font-bold text-gray-900">{statsToShow?.messageCount || 0}</p>}
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-xl">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Tool Calls</p>
                    <div className="flex items-center justify-between mt-3">
                      {isLoadingStatsToShow ? <IconSpinner className="text-gray-500" /> : <p className="text-xl font-bold text-gray-900">{statsToShow?.toolCalls || 0}</p>}
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-xl">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Tokens Used</p>
                    <div className="flex items-center justify-between mt-3">
                      {isLoadingStatsToShow ? <IconSpinner className="text-gray-500" /> : <p className="text-xl font-bold text-gray-900">{(statsToShow?.totalTokens || 0).toLocaleString()}</p>}
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-xl">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Feedback Score</p>
                    <div className="flex items-center justify-between mt-3">
                      {isLoadingStatsToShow ? (
                        <IconSpinner className="text-gray-500" />
                      ) : (
                        <p className="text-xl font-bold text-gray-900">
                          {feedbackScore !== null ? `${feedbackScore}%` : '—'}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="bg-white border border-gray-200 rounded-xl">
                  <div className="px-5 py-4">
                    <p className="text-xs font-medium tracking-wider text-gray-500 uppercase">Feedback Given</p>
                    <div className="flex items-center justify-between mt-3">
                      {isLoadingStatsToShow ? <IconSpinner className="text-gray-500" /> : <p className="text-xl font-bold text-gray-900">{statsToShow?.totalFeedback || 0}</p>}
                    </div>
                  </div>
                </div>


              </div>

              <div className="mt-6">
                <h3 className="text-base font-bold text-gray-900 mb-4">Recent Threads</h3>
                <div className="space-y-4">
                  {isLoadingConversationsToShow ? (
                    <div className="text-sm text-gray-500">Loading recent threads...</div>
                  ) : filteredConversations && filteredConversations.length > 0 ? (
                    filteredConversations.map((conversation) => <DisplayConversation key={conversation.id} conversation={conversation} isAdminView={isAdminView} />)
                  ) : (
                    <div className="text-sm text-gray-500">No conversations found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
