import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { RedirectToOrganizationProfile } from "@clerk/clerk-react";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import Models from "./ControlHub/Models";
import Integrations from "./ControlHub/Integrations";
import Agents from "./ControlHub/Agents";
import AgentConfig from "./ControlHub/AgentConfig";
import Settings from "./ControlHub/Settings";

const ControlHub = () => {
  return (
    <div className="flex flex-col h-full overflow-hidden py-4">
      <div className="flex-grow overflow-y-auto">
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <div className="max-w-md">
            <h1 className="text-2xl font-bold text-gray-900">Control Hub</h1>
          </div>
        </div>

        <div className="px-4 mx-auto mt-4 sm:px-6 md:px-8">
          <div className="border-b border-gray-200">
            <nav className="flex flex-wrap gap-8">
              <NavLink
                to="/control-hub"
                end
                className={({ isActive }) =>
                  `inline-flex items-center px-1 py-4 text-sm font-medium transition-all duration-200 border-b-2 ${isActive ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300"}`
                }
              >
                Agents
              </NavLink>
              <NavLink
                to="/control-hub/models"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 py-4 text-sm font-medium transition-all duration-200 border-b-2 ${isActive ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300"}`
                }
              >
                Models
              </NavLink>
              <NavLink
                to="/control-hub/integrations"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 py-4 text-sm font-medium transition-all duration-200 border-b-2 ${isActive ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300"}`
                }
              >
                Integrations
              </NavLink>
              <NavLink
                to="/control-hub/settings"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 py-4 text-sm font-medium transition-all duration-200 border-b-2 ${isActive ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300"}`
                }
              >
                Settings
              </NavLink>
              <NavLink
                to="/control-hub/organization"
                className={({ isActive }) =>
                  `inline-flex items-center px-1 py-4 text-sm font-medium transition-all duration-200 border-b-2 ${isActive ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300"}`
                }
              >
                My Organization
                <ExternalLinkIcon className="w-4 h-4 ml-2" />
              </NavLink>
            </nav>
          </div>

          <Routes>
            <Route index element={<Agents />} />
            <Route path="models" element={<Models />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="agents/:agentId" element={<AgentConfig isNewAgent={false} />} />
            <Route path="agents/new" element={<AgentConfig isNewAgent={true} />} />
            <Route path="organization" element={<RedirectToOrganizationProfile />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ControlHub;
