import { useState } from 'react';
import { HandThumbUpIcon as ThumbUpIcon, HandThumbDownIcon as ThumbDownIcon } from '@heroicons/react/24/outline';
import { toast } from 'sonner';
import { useAuthenticatedFetch } from '../hooks/use-authenticated-fetch';
import { useAuthenticatedSWR } from '../hooks/use-authenticated-swr';
import { cn } from '../lib/utils';

const FeedbackButton = ({ onClick, disabled, isPositive, selected }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={cn(
      'flex items-center space-x-1 px-4 py-2 rounded-md transition-colors',
      disabled ? 'opacity-50' : 'hover:bg-gray-100',
      selected && (isPositive ? 'bg-green-100' : 'bg-red-100')
    )}
  >
    {isPositive ? (
      <ThumbUpIcon className={cn('h-5 w-5', selected ? 'text-green-600' : 'text-gray-600')} />
    ) : (
      <ThumbDownIcon className={cn('h-5 w-5', selected ? 'text-red-600' : 'text-gray-600')} />
    )}
    <span className={cn('text-sm', selected ? (isPositive ? 'text-green-600' : 'text-red-600') : 'text-gray-600')}>
      {isPositive ? 'Yes' : 'No'}
    </span>
  </button>
);

export const ThreadFeedback = ({ threadId, agentId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authenticatedFetch = useAuthenticatedFetch();

  // Use SWR for fetching feedback
  const { data: feedbackData, mutate } = useAuthenticatedSWR(
    threadId ? `/api/feedback?conversationId=${threadId}` : null
  );

  const handleFeedback = async (isHelpful) => {
    setIsSubmitting(true);
    try {
      const response = await authenticatedFetch('/api/feedback', {
        method: 'POST',
        body: {
          conversationId: threadId,
          agentId,
          isHelpful,
        },
      });
      
      // Immediately update the SWR cache with the new feedback
      await mutate();
      toast.success('Feedback recorded and shared with your agent.');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('Failed to submit feedback. Please try again.');
    }
    setIsSubmitting(false);
  };

  if (feedbackData?.exists) {
    return (
      <div className="flex flex-col items-center space-y-2 py-4 border-t border-gray-200">
        <p className="text-sm text-gray-600">Thank you for your feedback! You can change your response below.</p>
        <div className="flex space-x-4">
          <FeedbackButton
            onClick={() => handleFeedback(true)}
            disabled={isSubmitting || feedbackData.data.isHelpful === true}
            isPositive={true}
            selected={feedbackData.data.isHelpful === true}
          />
          <FeedbackButton
            onClick={() => handleFeedback(false)}
            disabled={isSubmitting || feedbackData.data.isHelpful === false}
            isPositive={false}
            selected={feedbackData.data.isHelpful === false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center space-y-2 py-4 border-t border-gray-200">
      <p className="text-sm text-gray-600">Was this conversation helpful?</p>
      <div className="flex space-x-4">
        <FeedbackButton
          onClick={() => handleFeedback(true)}
          disabled={isSubmitting}
          isPositive={true}
          selected={false}
        />
        <FeedbackButton
          onClick={() => handleFeedback(false)}
          disabled={isSubmitting}
          isPositive={false}
          selected={false}
        />
      </div>
    </div>
  );
};
