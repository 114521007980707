import React, { createContext, useContext, useMemo } from 'react';
import { useAuth } from "@clerk/clerk-react";
import useSWR from 'swr';
import { useAuthenticatedSWR } from '../hooks/use-authenticated-swr';

const ConversationContext = createContext();

export const ConversationContextProvider = ({ children }) => {
    const { orgId } = useAuth();

    // SWR hook for fetching conversations
    const { data: conversations, error, mutate: refreshConversations } = useAuthenticatedSWR(`/api/getConversations?orgId=${orgId}`);

    const contextValue = useMemo(() => ({
        conversations: conversations || [],
        isLoadingConversations: !conversations && !error,
        refreshConversations,
        error
    }), [conversations, error, refreshConversations]);

    return (
        <ConversationContext.Provider value={contextValue}>
            {children}
        </ConversationContext.Provider>
    );
};

export const useConversation = () => {
    const context = useContext(ConversationContext);
    if (context === undefined) {
        throw new Error('useConversation must be used within a ConversationContextProvider');
    }
    return context;
};