import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

// Tool definition and schema
export const searchSnowflakeSchema = {
  search_snowflake: {
    parameters: {
      type: "object",
      required: ["query"],
      properties: {
        query: {
          type: "string",
          description: "Describe in detail the type of data you are looking for",
        },
      },
    },
    description: "Search Snowflake information schema for tables and columns",
  },
};

export const toolMetadata = {
  name: "search_snowflake",
  displayName: "Search Snowflake",
  description: "Search Snowflake for a given query",
  requiredIntegration: "snowflake",
};

const SearchSnowflake = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;
  const isReady = result && result.status;

  const parseResults = (resultString) => {
    try {
      return JSON.parse(resultString);
    } catch (error) {
      console.error("Error parsing result:", error);
      return [];
    }
  };

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <MagnifyingGlassIcon className="w-5 h-5" />}
          <span className="font-medium">Searching Snowflake for "{args.query}"</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              {parseResults(result.result?.content?.[0]?.[0] || "[]").map((item, index) => (
                <div key={index} className="border-b pb-2 last:border-b-0">
                  <div className="font-medium">Result {index + 1}</div>
                  <div className="mt-1 text-sm whitespace-pre-wrap">{item.table_info}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-red-600">Error searching Snowflake: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchSnowflake;
