import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";

export const askWebSchema = {
  ask_web: {
    parameters: {
      type: "object",
      required: ["query"],
      properties: {
        query: {
          type: "string",
          description: "The question to ask the web",
        },
      },
    },
    description: "Ask an Online LLM a Question, and get a response using real-time data from the web",
  },
};

export const toolMetadata = {
  name: "ask_web",
  displayName: "Ask an Online LLM a Question",
  description: "Ask an Online LLM a Question",
};

const AskWeb = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && (result.status === "success" || result.status === "error");

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} onClick={() => isReady && setIsExpanded(!isExpanded)}>
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <MagnifyingGlassIcon className="w-5 h-5" />}
          <span className="font-medium">Looking for "{args.query}"</span>
        </div>
        <div className="flex items-center">{isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div className="border-b pb-2">
                <div className="font-medium">Answer</div>
                <div className="mt-1 text-sm prose prose-stone max-w-none">
                  <ReactMarkdown rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["noopener", "noreferrer"] }]]} remarkPlugins={[remarkGfm]}>
                    {result.result.content.message}
                  </ReactMarkdown>
                </div>
              </div>
              {result.result.content.citations && result.result.content.citations.length > 0 && (
                <div>
                  <div className="font-medium">Sources</div>
                  <ul className="mt-1 text-sm space-y-1">
                    {result.result.content.citations.map((citation, index) => (
                      <li key={index}>
                        <a href={citation} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                          {citation}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="text-red-600">Error asking web: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AskWeb;
