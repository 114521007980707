import React, { useState } from "react";
import { UserGroupIcon, ChatBubbleBottomCenterTextIcon, ChartBarIcon, CpuChipIcon, ShieldCheckIcon, CogIcon, WrenchScrewdriverIcon, ArrowsPointingOutIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { SupportedIntegrations } from "./ControlHub/SupportedIntegrations";
import { ModelProviders } from "./ControlHub/ModelProviders";
import { GlobeAltIcon, CodeBracketIcon, CubeIcon, BoltIcon } from "@heroicons/react/24/outline";

const HomePage = () => {
  const [expanded, setExpanded] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("Share your work e-mail and we'll reach out if you're a good fit for early access.");
  const [activeScreenshot, setActiveScreenshot] = useState("multi-agent-teams");

  // adding integrations that are not in SupportedIntegrations, just for marketing purposes
  const additionalIntegrations = [
    {
      key: "call-api",
      name: "Call API",
      icon: CubeIcon,
      description: "Call any API endpoint",
    },
    {
      key: "call-webhook",
      name: "Call Webhook",
      icon: BoltIcon,
      description: "Trigger webhooks on Slack, Discord, etc.",
    },
    {
      key: "read-url",
      name: "Read URL",
      icon: GlobeAltIcon,
      description: "Browse the web and extract content",
    },
    {
      key: "code-interpreter",
      name: "Code Interpreter",
      icon: CodeBracketIcon,
      description: "Write and execute python code",
    },
  ];

  const screenshots = [
    { id: "multi-agent-teams", label: "Multi-Agent Teams", image: "/images/multi-agent.png" },
    { id: "admin-dashboard", label: "Admin Dashboard", image: "/images/dashboard.png" },
    { id: "bring-your-own-llm", label: "Bring Your Own LLM Provider", image: "/images/modelproviders.png" },
    { id: "enterprise-integrations", label: "Enterprise Integrations", image: "/images/integrations.png" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await fetch("/api/joinWaitlist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("Successfully joined the waitlist!");
        setEmail("");
      } else {
        setMessage(data.error || "An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="">
      <header className="py-4 bg-white sm:py-5">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <nav className="flex items-center justify-between">
            <div className="flex shrink-0">
              <a href="#" title="" className="flex">
                <img className="w-auto h-16" src="/aster.png" alt="" />
              </a>
            </div>

            <div className="flex md:hidden">
              <button type="button" className="text-gray-900" onClick={() => setExpanded(!expanded)} aria-expanded={expanded}>
                {!expanded ? (
                  <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>

            <div className="hidden md:block">
              <a
                href="/chat"
                title=""
                className="inline-flex items-center justify-center px-6 py-2 sm:py-2.5 text-base font-semibold text-white transition-all duration-200 bg-gray-900 rounded-lg sm:text-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Login
              </a>
            </div>
          </nav>

          {expanded && (
            <nav>
              <div className="px-1 pt-8 pb-4">
                <div className="grid gap-y-6">
                  <a
                    href="/chat"
                    title=""
                    className="inline-flex items-center justify-center px-6 py-2 text-base font-semibold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-lg hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                  >
                    Login
                  </a>
                </div>
              </div>
            </nav>
          )}
        </div>
      </header>

      <section className="relative py-12 bg-white sm:py-16 lg:py-20">
        <div className="absolute inset-0">
          <img className="object-cover w-full h-full" src="/grid-pattern.png" alt="" />
        </div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-xl mx-auto text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl font-poppins">
              Deploy AI Agents in <span className="text-indigo-600">Minutes</span>
            </h1>
            <p className="max-w-lg mx-auto mt-6 text-base font-normal leading-7 text-gray-500 font-inter">One control hub to configure and launch your agent workflows.</p>

            <form onSubmit={handleSubmit} className="max-w-md mx-auto mt-8 space-y-4 sm:space-x-4 sm:flex sm:space-y-0 sm:items-end">
              <div className="flex-1">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full px-4 py-3 sm:py-3.5 text-base font-medium text-gray-900 placeholder-gray-500 border border-gray-300 rounded-lg sm:text-sm focus:ring-gray-900 focus:border-gray-900"
                    placeholder="Email address"
                    required
                  />
                </div>
              </div>

              <div className="relative group">
                <div className="absolute transitiona-all duration-1000 opacity-70 inset-0 bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>

                <button
                  type="submit"
                  className="inline-flex relative items-center justify-center w-full sm:w-auto px-8 py-3 sm:text-sm text-base sm:py-3.5 font-semibold text-white transition-all duration-200 bg-indigo-600 border border-transparent rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                >
                  Join Waitlist
                </button>
              </div>
            </form>

            {message && <p className="mt-4 text-center text-sm font-medium text-gray-900">{message}</p>}

            <ul className="flex items-center justify-center mt-6 space-x-6 sm:space-x-8">
              <li className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="text-xs font-medium text-gray-900 sm:text-sm">Multi-agent teams</span>
              </li>

              <li className="flex items-center">
                <svg className="w-5 h-5 mr-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                <span className="text-xs font-medium text-gray-900 sm:text-sm">Your LLM providers and data sources</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-4xl mx-auto mt-2 sm:mt-4">
            <nav className="flex flex-col items-center justify-center space-y-5 text-center sm:flex-row sm:space-y-0 sm:space-x-10 md:space-x-16">
              {screenshots.map((screenshot) => (
                <a
                  key={screenshot.id}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveScreenshot(screenshot.id);
                  }}
                  className={`pb-2 text-xs font-semibold tracking-widest uppercase transition-all duration-200 border-b ${activeScreenshot === screenshot.id ? "text-blue-600 border-blue-600" : "text-gray-400 border-transparent hover:text-gray-900"}`}
                >
                  {screenshot.label}
                </a>
              ))}
            </nav>

            <div className="mt-6 sm:mt-8">
              {screenshots.map((screenshot) => (
                <img key={screenshot.id} className={`w-full shadow-2xl rounded-2xl ${activeScreenshot === screenshot.id ? "block" : "hidden"}`} src={screenshot.image} alt={`${screenshot.label} Screenshot`} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 bg-white sm:py-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:gap-y-16">
            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-blue-100" width="72" height="75" viewBox="0 0 72 75" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                </svg>
                <UserGroupIcon className="absolute text-blue-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Multi-Agent Teams</h3>
              <p className="mt-4 text-base text-gray-600">Create and manage teams of AI agents that work together to accomplish complex tasks and projects.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-purple-100" width="66" height="68" viewBox="0 0 66 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                </svg>
                <CpuChipIcon className="absolute text-purple-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Flexible LLM Integration</h3>
              <p className="mt-4 text-base text-gray-600">Support for all major LLM providers, allowing you to use your own API keys for OpenAI, Anthropic, Azure, Google, AWS, and more.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-green-100" width="66" height="68" viewBox="0 0 66 68" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z" />
                </svg>
                <ChartBarIcon className="absolute text-green-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Comprehensive Agent Management</h3>
              <p className="mt-4 text-base text-gray-600">Monitor and optimize AI agent performance, costs, and effectiveness through an intuitive dashboard.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-gray-100" width="65" height="70" viewBox="0 0 65 70" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M64.5 26C64.5 46.4345 56.4345 70 36 70C15.5655 70 0 53.9345 0 33.5C0 13.0655 13.0655 0 33.5 0C53.9345 0 64.5 5.56546 64.5 26Z" />
                </svg>
                <ShieldCheckIcon className="absolute text-gray-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Enterprise-Grade Security</h3>
              <p className="mt-4 text-base text-gray-600">Robust security features including SSO integration and advanced data protection measures to meet enterprise requirements.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-yellow-100" width="78" height="78" viewBox="0 0 78 78" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.49996 28.0002C4.09993 47.9554 14.1313 66.7885 35.5 71.5002C56.8688 76.2119 68.0999 58.4553 72.5 38.5001C76.9 18.5449 68.3688 12.711 47 7.99931C25.6312 3.28759 12.9 8.04499 8.49996 28.0002Z" />
                </svg>
                <CogIcon className="absolute text-yellow-500 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Evals and Feedback</h3>
              <p className="mt-4 text-base text-gray-600">Evaluate your agent's performance and provide feedback to track its behavior, all in one place.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-gray-100" width="62" height="64" viewBox="0 0 62 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z"></path>
                </svg>
                <WrenchScrewdriverIcon className="absolute text-gray-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Powerful Built-in Tools</h3>
              <p className="mt-4 text-base text-gray-600">Out-of-the-box functionality for RAG, SQL queries, web scraping, email communication, and more to enhance agent capabilities.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-orange-100" width="62" height="64" viewBox="0 0 62 64" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z" />
                </svg>
                <ChatBubbleBottomCenterTextIcon className="absolute text-orange-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Conversation Tracking</h3>
              <p className="mt-4 text-base text-gray-600">Review your agent's work for accuracy of actions and outputs.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-rose-100" width="72" height="75" viewBox="0 0 72 75" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z" />
                </svg>
                <ArrowsPointingOutIcon className="absolute text-rose-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Enterprise Tool Integrations</h3>
              <p className="mt-4 text-base text-gray-600">Connect your AI agents with enterprise platforms like Snowflake and Salesforce, as well as internal APIs and databases.</p>
            </div>

            <div>
              <div className="relative flex items-center justify-center mx-auto">
                <svg className="text-lime-100" width="62" height="65" viewBox="0 0 62 65" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z" />
                </svg>
                <AdjustmentsHorizontalIcon className="absolute text-lime-600 w-9 h-9" />
              </div>
              <h3 className="mt-8 text-lg font-semibold text-black">Agents that Reason</h3>
              <p className="mt-4 text-base text-gray-600">No DAGs, Chains, or Graphs. Give your agents instructions and tools, and let them decide the best way to accomplish their tasks.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-poppins">Tool Call Integrations</h2>
            <p className="mt-4 text-base text-gray-600 sm:text-lg">Connect your AI agents with the products and systems that power your business</p>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-12 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
            {[...SupportedIntegrations, ...additionalIntegrations].map((integration) => (
              <div key={integration.key} className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
                {integration.logoUrl ? (
                  <img src={integration.logoUrl} alt={integration.name} className="w-16 h-16 mb-3 object-contain" />
                ) : integration.icon ? (
                  <integration.icon className="w-16 h-16 mb-3 text-gray-600" />
                ) : null}
                <h3 className="text-sm font-semibold text-center text-gray-900">{integration.name}</h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 bg-white sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-poppins">Supported AI Models</h2>
            <p className="mt-4 text-base text-gray-600 sm:text-lg">Choose from a wide range of AI model providers</p>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-12 sm:grid-cols-3 lg:grid-cols-4">
            {ModelProviders.map((provider) => (
              <div key={provider.key} className="flex flex-col items-center p-6 bg-gray-50 rounded-xl">
                <img src={provider.logoUrl} alt={provider.name} className="w-20 h-20 mb-4 object-contain" />
                <h3 className="text-lg font-semibold text-center text-gray-900">{provider.name}</h3>
                <p className="mt-2 text-sm text-center text-gray-600">{provider.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
          <img className="mx-auto h-20" src="https://www.asterave.com/aster_logo.png" alt="" />
          <figure className="mt-10">
            <blockquote className="text-center text-lg font-semibold leading-8 text-gray-900 sm:leading-9">
              <p>
                Aster Agents is built by{" "}
                <a href="http://www.asterave.com" target="_blank" rel="noopener noreferrer" className="underline">
                  Aster
                </a>
                , a small team that is passionate about generative AI and the future of work.
              </p>
            </blockquote>
          </figure>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
