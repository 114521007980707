import React, { useState } from "react";
import { GlobeIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

// Tool definition and schema
export const scrapeUrlSchema = {
  scrape_url: {
    parameters: {
      type: "object",
      required: ["url"],
      properties: {
        url: {
          type: "string",
          description: "The URL to scrape",
        },
        tables_only: {
          type: "boolean",
          default: false,
          description: "Whether to scrape only tables or all content",
        },
        raw_html: {
          type: "boolean",
          default: false,
          description: "If true, return the raw HTML of the page; otherwise, return the parsed text",
        },
      },
    },
    description: "Scrape content from a given URL",
  },
};

export const toolMetadata = {
  name: "scrape_url",
  displayName: "Scrape URL",
  description: "Scrape content from a given URL",
};

const ScrapeUrl = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;

  const isReady = result && result.status;

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} onClick={() => isReady && setIsExpanded(!isExpanded)}>
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <GlobeIcon className="w-5 h-5" />}
          <span className="font-medium">Reading URL: {args.url}</span>
        </div>
        <div className="flex items-center">{isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              <div>
                <strong>URL:</strong>{" "}
                <a href={args.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                  {args.url}
                </a>
              </div>
              <div className="border-b pb-2">
                <div className="font-medium">Web Page Content</div>
                <div className="mt-1 text-sm whitespace-pre-wrap">{result.result.content}</div>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error reading URL: {result.error || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScrapeUrl;
