import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";

export const callApiSchema = {
  call_api: {
    parameters: {
      type: "object",
      required: ["endpoint_url", "request_type", "params"],
      properties: {
        endpoint_url: {
          type: "string",
          description: "The URL of the API endpoint to call",
        },
        request_type: {
          type: "string",
          description: "The type of request to make (GET, POST, PUT, DELETE)",
        },
        params: {
          type: "object",
          description: "The parameters to pass to the API endpoint",
        },
      },
    },
    description: "Call an API endpoint and get a response",
  },
};

export const toolMetadata = {
  name: "call_api",
  displayName: "Call an API Endpoint",
  description: "Call an API endpoint and get a response",
};

const CallApi = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && (result.status === "success" || result.status === "error");

  const renderContent = (content) => {
    if (typeof content === 'string') {
      return (
        <ReactMarkdown rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["noopener", "noreferrer"] }]]} remarkPlugins={[remarkGfm]}>
          {content}
        </ReactMarkdown>
      );
    } else if (typeof content === 'object') {
      return <pre className="whitespace-pre-wrap">{JSON.stringify(content, null, 2)}</pre>;
    } else {
      return <span>{String(content)}</span>;
    }
  };

  return (
    <div className="border rounded-lg overflow-hidden">
      <div className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} onClick={() => isReady && setIsExpanded(!isExpanded)}>
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <MagnifyingGlassIcon className="w-5 h-5" />}
          <span className="font-medium">Calling "{args.endpoint_url}"</span>
        </div>
        <div className="flex items-center">{isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-4">
              {args.params && (
                <div className="border-b pb-2">
                  <div className="font-medium">Parameters</div>
                  <div className="mt-1 text-sm prose prose-stone max-w-none">
                    {renderContent(args.params)}
                  </div>
                </div>
              )}
              <div className="border-b pb-2">
                <div className="font-medium">Response</div>
                <div className="mt-1 text-sm prose prose-stone max-w-none">
                  {renderContent(result.result.content)}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-red-600">Error calling API: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CallApi;
