import React, { useState } from "react";
import { MagnifyingGlassIcon, CodeIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

export const searchGoogleSchema = {
  search_google: {
    parameters: {
      type: "object",
      required: ["query"],
      properties: {
        query: {
          type: "string",
          description: "The search query",
        },
      },
    },
    description: "Search Google for a given search query",
  },
};

export const toolMetadata = {
  name: "search_google",
  displayName: "Search Google",
  description: "Search Google for a given search query",
};

const SearchGoogle = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRawData, setShowRawData] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && result.result && (result.result.status === "success" || result.result.status === "error");

  const renderResults = (content) => {
    return (
      <div className="space-y-4">
        {content.organic_results && (
          <div className="border-b pb-2">
            <div className="font-medium">Organic Results</div>
            <div className="mt-1 text-sm whitespace-pre-wrap">
              {content.organic_results.map((item, index) => (
                <div key={index} className="mb-2">
                  <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{item.title}</a>
                  <p>{item.snippet}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {content.answer_box && (
          <div className="border-b pb-2">
            <div className="font-medium">Answer Box</div>
            <div className="mt-1 text-sm whitespace-pre-wrap">
              {content.answer_box.answers?.map((answer, index) => (
                <div key={index}>
                  <p><strong>{answer.category}:</strong> {answer.answer}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {content.related_questions && (
          <div className="border-b pb-2">
            <div className="font-medium">Related Questions</div>
            <div className="mt-1 text-sm whitespace-pre-wrap">
              {content.related_questions.map((question, index) => (
                <div key={index} className="mb-2">
                  <p><strong>Q: {question.question}</strong></p>
                  <p>A: {question.answer}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <MagnifyingGlassIcon className="w-5 h-5" />}
          <span className="font-medium">Searching Web for "{args.query}"</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.result.status === "success" ? (
            renderResults(result.result.content)
          ) : (
            <div className="text-red-600">Error searching web: {result.result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchGoogle;