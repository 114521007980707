import React from "react";
import { UserButton, OrganizationSwitcher } from "@clerk/clerk-react";

const Header = ({ toggleSidebar, isExpanded }) => {
  return (
    <header className="bg-white">
      <div className="px-4 mx-auto">
        <div className="flex items-center justify-between h-16">

          <div className="flex ml-6 xl:ml-0">
            <div className="flex items-center flex-shrink-0">
              <img className="block w-auto h-8 lg:hidden" src="/icon.png" alt="" />
              <img className="hidden w-auto h-12 lg:block" src="/aster.png" alt="" />
            </div>
          </div>

          <div className="flex items-center justify-end ml-auto space-x-6">
            <OrganizationSwitcher hidePersonal={true} afterCreateOrganizationUrl="/control-hub" afterSelectOrganizationUrl="/chat" />
            <button type="button" className="flex items-center max-w-xs rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
              <UserButton />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
