import useSWR from 'swr';
import { useAuth } from '@clerk/clerk-react';
import { useAuthenticatedFetch } from './use-authenticated-fetch';

export const useAuthenticatedSWR = (key, swrOptions = {}) => {
  const { isLoaded, isSignedIn } = useAuth();
  const authenticatedFetch = useAuthenticatedFetch();

  return useSWR(
    isLoaded && isSignedIn ? key : null,
    authenticatedFetch,
    swrOptions
  );
};