import React, { useState, useEffect, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Switch, Dialog, Transition } from "@headlessui/react";
import { useParams, useNavigate } from "react-router-dom";
import { Pencil1Icon, TrashIcon, GearIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";
import { useAuth } from "@clerk/clerk-react";

import { Separator } from "../../ui/Separator";
import { useAI } from "../../contexts/AIContext";
import { tools, getToolsJson } from "../Tools";
import { getSupportedModels } from "./SupportedModels";
import { useAuthenticatedFetch } from "../../hooks/use-authenticated-fetch";
import { SupportedIntegrations } from "./SupportedIntegrations";

const ToolSelector = ({ metadata, isSelected, onToggle, disabled }) => {
  return (
    <div className={`flex items-center justify-between py-2 ${disabled ? 'opacity-50' : ''}`}>
      <div className="flex flex-col">
        <span className="text-sm font-medium text-gray-900">{metadata.name}</span>
        <span className="text-xs text-gray-500">
          {disabled ? 'Integration required' : metadata.description}
        </span>
      </div>
      <Switch
        checked={isSelected}
        onChange={onToggle}
        className={`${isSelected ? "bg-indigo-600" : "bg-gray-200"} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        disabled={disabled}
      >
        <span className={`${isSelected ? "translate-x-6" : "translate-x-1"} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`} />
      </Switch>
    </div>
  );
};

const AgentConfig = ({ isNewAgent }) => {
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { orgId } = useAuth();
  const { userInfo, fetchAgents, agents, integrations } = useAI();
  const authenticatedFetch = useAuthenticatedFetch();

  const currentAgent = !isNewAgent ? agents.find((agent) => agent.id === parseInt(agentId, 10)) : null;

  // Initialize form with empty/default values
  const initialDefaultValues = {
    name: "",
    description: "",
    model: "",
    maxToolRoundtrips: 20,
    logoUrl: "",
    instructions: "",
    starters: [],
    selectedTools: {},
    customTools: [],
  };

  const { register, handleSubmit, control, setValue, getValues, watch, reset } = useForm({
    defaultValues: initialDefaultValues,
  });

  const {
    fields: starterFields,
    append: appendStarter,
    remove: removeStarter,
  } = useFieldArray({
    control,
    name: "starters",
  });

  const {
    fields: customToolFields,
    append: appendCustomTool,
    remove: removeCustomTool,
    update: updateCustomTool,
  } = useFieldArray({
    control,
    name: "customTools",
  });

  // Dialog state for Custom Tools
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingToolIndex, setEditingToolIndex] = useState(null);
  const [newCustomTool, setNewCustomTool] = useState("");

  // Get available models for the current user's organization
  const availableModels = React.useMemo(() => {
    if (userInfo && userInfo.organizations) {
      const currentOrg = userInfo.organizations.find((org) => org.id === orgId);
      if (currentOrg && currentOrg.connections) {
        return getSupportedModels(currentOrg.connections);
      }
    }
    return [];
  }, [userInfo, orgId]);

  const groupedTools = useMemo(() => {
    const groups = {
      General: [],
    };

    tools.forEach(tool => {
      const integration = tool.metadata.requiredIntegration;
      if (integration) {
        if (!groups[integration]) {
          groups[integration] = [];
        }
        groups[integration].push(tool);
      } else {
        groups.General.push(tool);
      }
    });

    return groups;
  }, []);

  // Reset form values when currentAgent or availableModels change
  useEffect(() => {
    if ((isNewAgent || currentAgent) && availableModels.length > 0) {
      const defaultValues = currentAgent
        ? {
            name: currentAgent.name,
            description: currentAgent.description,
            model: currentAgent.model,
            maxToolRoundtrips: currentAgent.maxToolRoundtrips,
            logoUrl: currentAgent.logoUrl,
            instructions: currentAgent.systemPrompt,
            starters: currentAgent.conversationStarters || [],
            selectedTools: tools.reduce((acc, tool) => {
              acc[tool.metadata.name] = !!currentAgent.tools?.[tool.metadata.name];
              return acc;
            }, {}),
            customTools: Object.entries(currentAgent.tools || {})
              .filter(([toolName]) => !tools.find((tool) => tool.metadata.name === toolName))
              .map(([toolName, toolData]) => ({ [toolName]: toolData })),
          }
        : {
            name: "New Agent",
            description: "",
            model: availableModels.length > 0 ? availableModels[0].key : "",
            maxToolRoundtrips: 20,
            logoUrl: "",
            instructions: "",
            starters: [],
            selectedTools: {},
            customTools: [],
          };
      reset(defaultValues);
    }
  }, [currentAgent, isNewAgent, availableModels, reset]);

  // Watch selected tools
  const watchSelectedTools = watch("selectedTools");

  // Open and close dialog functions
  const openDialog = (index = null) => {
    if (index !== null) {
      const tool = getValues(`customTools.${index}`);
      setNewCustomTool(JSON.stringify(tool, null, 2));
      setEditingToolIndex(index);
    } else {
      setNewCustomTool("");
      setEditingToolIndex(null);
    }
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setNewCustomTool("");
    setEditingToolIndex(null);
  };

  // Handler functions
  const handleAddOrUpdateCustomTool = () => {
    try {
      const toolJson = JSON.parse(newCustomTool);

      if (editingToolIndex !== null) {
        updateCustomTool(editingToolIndex, toolJson);
      } else {
        appendCustomTool(toolJson);
      }
      closeDialog();
    } catch (error) {
      alert("Invalid JSON. Please check your input.");
    }
  };

  const handleSelectExistingTool = (toolName) => {
    const selectedTool = tools.find((tool) => tool.metadata.name === toolName);
    if (selectedTool) {
      const toolJson = {
        [selectedTool.metadata.name]: {
          ...selectedTool.schema[selectedTool.metadata.name],
          description: selectedTool.metadata.description,
        },
      };
      setNewCustomTool(JSON.stringify(toolJson, null, 2));
    }
  };

  const handleDeleteAgent = async () => {
    if (isNewAgent) {
      toast.error("Cannot delete a new agent.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this agent? This action cannot be undone.")) {
      return;
    }

    try {
      const response = await authenticatedFetch(`/api/agents/deleteAgent`, {
        method: "DELETE",
        body: { agentId: parseInt(agentId, 10) },
      });

      if (response) {
        toast.success("Agent deleted successfully!");
        await fetchAgents(); // Refresh the agents list
        navigate("/control-hub"); // Redirect to agents list
      }
    } catch (error) {
      console.error("Error deleting agent:", error);
      toast.error("Failed to delete agent. Please try again.");
    }
  };

  const onSubmit = async (data) => {
    try {
      const toolsJson = getToolsJson(data.selectedTools);
      const customToolsJson = data.customTools.reduce((acc, tool) => {
        const toolName = Object.keys(tool)[0];
        acc[toolName] = tool[toolName];
        return acc;
      }, {});
      const allTools = {
        ...toolsJson,
        ...customToolsJson,
      };

      const agentData = {
        ...(isNewAgent ? {} : { id: parseInt(agentId, 10) }),
        name: data.name,
        description: data.description,
        model: data.model,
        maxToolRoundtrips: data.maxToolRoundtrips,
        systemPrompt: data.instructions,
        conversationStarters: data.starters,
        logoUrl: data.logoUrl,
        tools: allTools,
      };

      const response = await authenticatedFetch("/api/agents/updateAgent", {
        method: "POST",
        body: agentData,
      });

      if (response) {
        toast.success(`Agent ${isNewAgent ? "created" : "updated"} successfully!`);
        await fetchAgents(); // Refresh the agents list
        navigate("/control-hub"); // Redirect to agents list
      }
    } catch (error) {
      console.error(`Error ${isNewAgent ? "creating" : "updating"} agent:`, error);
      toast.error(`Failed to ${isNewAgent ? "create" : "update"} agent. Please try again.`);
    }
  };

  return (
    <div className="max-w-3xl mx-auto my-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">{isNewAgent ? "Create New Agent" : "Edit Agent"}</h2>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {/* Name */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input {...register("name")} type="text" id="name" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        {/* Description */}
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <input {...register("description")} type="text" id="description" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        {/* Logo URL */}
        <div>
          <label htmlFor="logoUrl" className="block text-sm font-medium text-gray-700">
            Logo URL
          </label>
          <input {...register("logoUrl")} type="text" id="logoUrl" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        {/* Model */}
        <div>
          <label htmlFor="model" className="block text-sm font-medium text-gray-700">
            Model
          </label>
          <select {...register("model")} id="model" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            {availableModels.length === 0 && <option value="">No models available. Please connect a model provider.</option>}
            {availableModels.map((supportedModel) => (
              <option key={supportedModel.key} value={supportedModel.key}>
                {supportedModel.name} ({supportedModel.provider})
              </option>
            ))}
          </select>
        </div>

        {/* Instructions */}
        <div>
          <label htmlFor="instructions" className="block text-sm font-medium text-gray-700">
            Instructions
          </label>
          <textarea {...register("instructions")} id="instructions" rows={10} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
        </div>

        {/* Tools */}
        <div className="mt-6 space-y-8">
          <h3 className="text-lg font-semibold text-gray-900">Tools</h3>
          
          {Object.entries(groupedTools).map(([groupName, groupTools], index) => {
            const integration = SupportedIntegrations.find(i => i.key === groupName);
            const displayName = integration?.name || groupName;

            return (
              <div key={groupName}>
                <div className="flex items-center mb-4">
                  {integration?.logoUrl ? (
                    <img 
                      src={integration.logoUrl} 
                      alt={`${displayName} logo`} 
                      className="h-6 w-6 mr-3" 
                    />
                  ) : (
                    <GearIcon className="w-6 h-6 mr-3 text-gray-500" />
                  )}
                  <h4 className="text-md font-medium text-gray-900">{displayName}</h4>
                </div>
                <div className="space-y-3 ml-9">
                  {groupTools.map((tool) => {
                    const isAvailable = !tool.metadata.requiredIntegration || 
                      integrations.find(i => i.key === tool.metadata.requiredIntegration)?.isConnected;
                    
                    return (
                      <ToolSelector
                        key={tool.metadata.name}
                        metadata={tool.metadata}
                        isSelected={watchSelectedTools[tool.metadata.name] || false}
                        onToggle={() => {
                          setValue(`selectedTools.${tool.metadata.name}`, !watchSelectedTools[tool.metadata.name]);
                        }}
                        disabled={!isAvailable}
                      />
                    );
                  })}
                </div>
                {index < Object.entries(groupedTools).length - 1 && (
                  <Separator className="my-6" />
                )}
              </div>
            );
          })}
        </div>

        {/* Custom Tools */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Custom Tools</label>
          <div className="space-y-2">
            {customToolFields.map((field, index) => {
              const toolName = Object.keys(field)[0];
              const toolData = field[toolName];
              return (
                <div key={field.id} className="flex items-center justify-between py-2">
                  <div className="flex flex-col flex-grow mr-4">
                    <span className="text-sm font-medium text-gray-900">{toolName}</span>
                    <span className="text-xs text-gray-500">{toolData.description}</span>
                  </div>
                  <div className="flex items-center">
                    <button type="button" onClick={() => openDialog(index)} className="text-blue-600 bg-blue-100 p-2 rounded-full hover:bg-blue-200 transition-colors duration-200" aria-label="Edit">
                      <Pencil1Icon className="w-5 h-5" />
                    </button>
                    <button type="button" onClick={() => removeCustomTool(index)} className="text-red-600 bg-red-100 p-2 rounded-full hover:bg-red-200 transition-colors duration-200 ml-2" aria-label="Remove">
                      <TrashIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <button
            type="button"
            onClick={() => openDialog()}
            className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Custom Tool
          </button>
        </div>

        {/* Conversation Starters */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Conversation Starters</label>
          {starterFields.map((field, index) => (
            <div key={field.id} className="flex items-center mb-2">
              <input {...register(`starters.${index}`)} type="text" className="flex-grow border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <button type="button" onClick={() => removeStarter(index)} className="ml-2 text-red-600 bg-red-100 p-2 rounded-full hover:bg-red-200 transition-colors duration-200" aria-label="Remove">
                <TrashIcon className="w-5 h-5" />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => appendStarter("")}
            className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Conversation Starter
          </button>
        </div>

        {/* Actions */}
        <div className="flex justify-end space-x-4">
          {!isNewAgent && (
            <button
              type="button"
              onClick={handleDeleteAgent}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete Agent
            </button>
          )}

          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {isNewAgent ? "Create Agent" : "Update Agent"}
          </button>
        </div>
      </form>

      {/* Custom Tool Dialog */}
      <Transition appear show={isDialogOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDialog}>
          <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={React.Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {editingToolIndex !== null ? "Edit Custom Tool" : "Add Custom Tool"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mt-4 mb-6">
                      <label htmlFor="toolTemplate" className="block text-sm font-medium text-gray-700 mb-2">
                        Select an existing tool as template
                      </label>
                      <select
                        id="toolTemplate"
                        onChange={(e) => handleSelectExistingTool(e.target.value)}
                        className="block w-full px-3 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">Choose a tool...</option>
                        {tools.map((tool) => (
                          <option key={tool.metadata.name} value={tool.metadata.name}>
                            {tool.metadata.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <textarea
                      value={newCustomTool}
                      onChange={(e) => setNewCustomTool(e.target.value)}
                      placeholder="Enter custom tool JSON here"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      rows={10}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                      onClick={handleAddOrUpdateCustomTool}
                    >
                      {editingToolIndex !== null ? "Update" : "Add"}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={closeDialog}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default AgentConfig;
